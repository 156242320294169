import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeCompare, faComments, faGolfBallTee } from '@fortawesome/free-solid-svg-icons';

import React, { useState } from 'react';
//1280 x 1202 px
import { Link } from 'react-router-dom';

import IconLogo from '../../img/brand/icononly_nobuffer_small.png';

function About() {
  const [activeContent, setActiveContent] = useState('lists');
  const menuButton =
    'flex flex-row flex-nowrap justify-center mx-auto items-center text-indigo-600 rounded-md p-2 my-4 hover:bg-indigo-800 hover:text-white border border-indigo-800 lg:w-36 xl:w-44';
  const clickedMenuButton =
    'flex flex-row flex-nowrap justify-center mx-auto items-center bg-indigo-800 text-white rounded-md p-2 my-4 border border-indigo-800 lg:w-36 xl:w-44';

  const renderContent = () => {
    switch (activeContent) {
      case 'lists':
        return (
          <div>
            <p className="text-sm sm:text-base">
              Compare golf courses, practice facilities and trips in our unique
              golf lists. Golf Lists gives you thousands of golf club websites
              on one page making trip planning 10 times faster.{' '}
              <Link
                to="/lists/golf-courses/rankings"
                className="text-indigo-700 no-underline hover:italic hover:underline font-bold"
              >
                Want to plan better golf faster and cheaper?
              </Link>
            </p>
            
          </div>
        );
      case 'profile':
        return (
          <div>
            <p className="text-sm sm:text-base">
              Record the courses you have played and the courses on your bucket
              list in your Profile. Review your golfing history on a
              personalised dashboard of golfing exploration. Gain Golf List
              badges recognising your golfing achievements.{' '}
              <Link
                to="/profile"
                className="text-indigo-700 no-underline hover:italic hover:underline font-bold"
              >
                Want to record your golfing exploits?
              </Link>
            </p>
          </div>
        );
      case 'connect':
        return (
          <div>
            <p className="text-sm sm:text-base">
              Join our forum to connect with verified British and Irish golf
              club members up and down the British Isles, report bugs and
              suggest improvements to GolfLists.com. Help grow the community and
              start a conversation!{' '}
              <Link
                to="/connect"
                className="text-indigo-700 no-underline hover:italic hover:underline font-bold"
              >
                Want to connect with golf club members?
              </Link>
            </p>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <>
      <section className="container mx-auto py-6 sm:py-12">
        <h2 className="mt-2 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Why join Golf Lists?
        </h2>
        <div className="flex flex-row flex-nowrap justify-between sm:justify-center items-center mx-auto w-full sm:w-2/3 md:w-1/2">
          <button
            className={
              activeContent === 'lists' ? clickedMenuButton : menuButton
            }
            onClick={() => setActiveContent('lists')}
          >
            <FontAwesomeIcon icon={faCodeCompare} className="mr-2" />
            Lists
          </button>

          <button
            className={
              activeContent === 'connect' ? clickedMenuButton : menuButton
            }
            onClick={() => setActiveContent('connect')}
          >
            <FontAwesomeIcon icon={faComments} className="mr-2" />
            Connect
          </button>

          <button
            className={
              activeContent === 'profile' ? clickedMenuButton : menuButton
            }
            onClick={() => setActiveContent('profile')}
          >
            <FontAwesomeIcon icon={faGolfBallTee} className="mr-2" />
            Profile
          </button>
        </div>
        <div>
          <article className="container mx-auto prose px-6">
            {renderContent()}
          </article>
          <img
            loading="lazy"
            width="51"
            height="48"
            className="mx-auto h-8 w-auto sm:hidden" // Tailwind classes to control size
            src={IconLogo}
            alt="GolfLists.com logo"
          />
        </div>
      </section>
    </>
  );
}

export default About;
